<template>
  <v-bottom-sheet v-model="dialog" inset scrollable>
    <v-card>
      <v-card-text style="height: 80vh" class="px-0">
        <v-sheet>
          <v-btn
            @click="dialog = false"
            color="pink"
            dark
            absolute
            top
            right
            fab
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="pa-5">
            <v-row v-if="viewType == 1" justify="center">
              <v-col cols="12" sm="8" md="6" lg="4">
                <!-- <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-img
                              :src="user.profile_pic_link"
                            ></v-img>
                          </v-list-item-avatar>
                        </v-list-item>

                        <v-list-item link>
                          <v-list-item-content>
                            <v-list-item-title class="title"
                              >{{ user.firstname + ' ' + user.lastname}}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >john@vuetifyjs.com</v-list-item-subtitle
                            >
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon>mdi-menu-down</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>

                    </v-card> -->

                <v-card>
                  <v-img :src="user.profile_pic_link" dark>
                    <!-- height="300px" -->
                    <!-- <v-row class="fill-height">
                          <v-card-title>
                            <v-btn dark icon>
                              <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn dark icon class="mr-4">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn dark icon>
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </v-card-title>

                          <v-spacer></v-spacer>

                          <v-card-title class="white--text pl-12 pt-12">
                            <div class="display-1 pl-12 pt-12">
                              {{ user.firstname + " " + user.lastname }}
                            </div>
                          </v-card-title>
                        </v-row> -->
                  </v-img>

                  <v-list>
                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title class="title">{{
                            user.firstname + " " + user.lastname
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            user.email
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <v-list-item @click="showUploadPicture = true">
                        <v-list-item-icon>
                          <v-icon color="indigo"
                            >mdi-account-box-outline</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Change Profile Picture</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item @click="showUserProfile = true">
                        <v-list-item-icon>
                          <v-icon color="indigo">mdi-account-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>My Profile</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <!-- TODO <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Settings</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item> -->
                    </v-list-group>
                    <v-divider></v-divider>
                    <!-- <v-list-item link>
                          <v-list-item-content>
                            <v-list-item-title class="title">{{
                              user.firstname + " " + user.lastname
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >john@vuetifyjs.com</v-list-item-subtitle
                            >
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon>mdi-menu-down</v-icon>
                          </v-list-item-action>
                        </v-list-item> -->
                  </v-list>
                  <v-list>
                    <v-list-item :href="fpUrl()">
                      <v-list-item-icon>
                        <v-avatar tile size="24">
                          <v-img
                            src="@/assets/images/feeplus_logo_sm.png"
                          ></v-img>
                        </v-avatar>
                        <!-- <v-icon color="indigo">mdi-home</v-icon> -->
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>FeePlus Homepage</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- TODO <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-bell</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Notifications</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->

                    <v-divider></v-divider>

                    <v-list-item @click="userSignOut()">
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Sign Out</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <v-card max-width="500" class="mx-auto">
                  <v-toolbar color="deep-purple accent-4" dark>
                    <v-toolbar-title
                      ><v-icon>mdi-monitor-dashboard</v-icon> Business
                      Console</v-toolbar-title
                    >

                    <!-- <v-spacer></v-spacer>
                    // NOTE: if those with business will know here is the place to go. If the list is empty, there will be a note for them
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-help-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>This section will show the businesses list you owned or you
                    work with.</span>
                    </v-tooltip> -->
                  </v-toolbar>

                  <v-list v-if="user.businesses">
                    <!-- <v-subheader>Recent chat</v-subheader> -->

                    <v-list-item
                      v-for="business in user.businesses"
                      :key="business.id"
                      :href="
                        fpUrl() + '/business/' + business.id + '/dashboard'
                      "
                    >
                      <v-list-item-avatar tile>
                        <v-img :src="business.logo_pic_link"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="business.name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <!-- <v-list-item-icon>
                            <v-icon
                              :color="
                                item.active ? 'deep-purple accent-4' : 'grey'
                              "
                              >chat_bubble</v-icon
                            >
                          </v-list-item-icon> -->
                    </v-list-item>
                  </v-list>
                  <v-card-text v-else>
                    This section will show the business list you owned or you
                    work with.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" sm="12" md="8">
                <sign-in
                  v-if="viewType == 2"
                  v-on:success="closeDialog"
                ></sign-in>
              </v-col>
            </v-row>

            <!-- <div class="d-flex flex-wrap">
                  <v-avatar class="profile" color="grey" size="100">
                    <v-img
                      v-if="user.profile_pic_link"
                      :src="user.profile_pic_link"
                    ></v-img>
                    <span
                      v-else-if="user.initial"
                      class="white--text headline"
                      >{{ user.initial }}</span
                    >
                    <v-icon v-else class="white--text"
                      >mdi-account-circle</v-icon
                    >
                  </v-avatar>
                  <div>
                    <div class="text-h4 ma-4">
                      {{ user.firstname + " " + user.lastname }}
                    </div>
                  </div>
                </div> -->
          </div>
        </v-sheet>
      </v-card-text>
    </v-card>
    <upload-picture
      v-if="showUploadPicture"
      ref="uploadPicture"
      @save="savePicture"
      @close="showUploadPicture = false"
      dialogTitle="Upload Profile Picture"
    />
    <user-profile
      v-if="showUserProfile"
      @close="showUserProfile = false"
      ref="userProfile"
    />
  </v-bottom-sheet>
</template>
<script>
import dialogMixin from "@/mixins/dialogMixin";

export default {
  data() {
    return {
      viewUser: false, // user dropdown
      showUploadPicture: false,
      showUserProfile: false,
      viewType: 0, // 1 = user, 2 = not user
    };
  },
  created() {
    this.viewType = this.fpUser.uid && this.fpUser.uak ? 1 : 2;
    this.showDialog();
  },
  methods: {
    fpUrl() {
      return process.env.VUE_APP_FEEPLUS_URL;
    },
    userSignOut() {
      this.$store.dispatch("userSignOut").then((r) => {
        if (r) {
          this.$router.go();
        }
      });
    },
    savePicture(imageData) {
      this.$store
        .dispatch({
          type: "setPicture",
          imageData: imageData,
        })
        .then((res) => {
          this.$refs.uploadPicture.close(res);
        });
    },
  },
  computed: {
    business() {
      return this.$store.state.business;
    },
    fpUser() {
      return this.$store.state.fp_user;
    },
    user() {
      return this.$store.state.user;
    },
  },
  components: {
    signIn: () => import(/* webpackChunkName: "signIn" */ "./SignIn"),
    uploadPicture: () =>
      import(/* webpackChunkName: "uploadPicture" */ "./UploadPicture"),
    userProfile: () =>
      import(/* webpackChunkName: "userProfile" */ "./UserProfile"),
  },
  mixins: [dialogMixin],
};
</script>
