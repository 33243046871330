import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/error/404",
    name: "404",
    component: () => import(
      /* webpackChunkName: "404" */ "../views/404"
    ),
  },
  {
    path: "/signin/google",
    name: "SignInGoogle",
    component: () => import(
      /* webpackChunkName: "SignInGoogle" */ "../components/SignInGoogle"
    ),
  },
  {
    path: "/:bid", // /from?/:from?
    name: "Home",
    props: true,
    component: Home,
    children: [
      {
        path: "/:bid/schedules", // default page
        name: "HomeSchedules",
        component: () => import(
          /* webpackChunkName: "HomeSchedules" */ "../views/HomeSchedules"
        ),
      },
      {
        path: "/:bid/customers/:cid/notes",
        name: "MyNotes",
        props: true,
        component: () => import(
          /* webpackChunkName: "MyEvent" */ "../views/MyNotes"
        ),
      },
      {
        path: "/:bid/customers/:cid/subscriptions/:scid", // default page
        name: "MySubscription",
        props: true,
        component: () => import(
          /* webpackChunkName: "MySubscription" */ "../views/MySubscription"
        ),
      },
      {
        path: "/:bid/customers/:cid/events/:event_id?/:event_date?",
        name: "MyEvent",
        props: true,
        component: () => import(
          /* webpackChunkName: "MyEvent" */ "../views/MyEvent"
        ),
      },
      {
        path: "/:bid/customers/:cid/appointment",
        name: "MyAppointment",
        props: true,
        component: () => import(
          /* webpackChunkName: "MyAppointment" */ "../views/MyAppointment"
        ),
      },
      {
        path: "/:bid/customers/:cid/pay/:iid?",
        name: "PayOnline",
        props: true,
        component: () => import(
          /* webpackChunkName: "PayOnline" */ "../views/PayOnline"
        ),
      },
      {
        path: "/:bid/signup",
        name: "CreateAccount",
        props: true,
        component: () => import(
          /* webpackChunkName: "CreateAccount" */ "../views/CreateAccount"
        ),
      },
      {
        path: "/:bid/pdf/:src",
        name: "ViewPdf",
        props: true,
        component: () => import(
          /* webpackChunkName: "ViewPdf" */ "../views/ViewPdf"
        ),
      },
      {
        path: "/:bid/register",
        name: "RegistrationForm",
        props: true,
        component: () => import(
          /* webpackChunkName: "PayOnline" */ "../views/RegistrationForm"
        ),
      },
      {
        path: "", // default page
        name: "HomeMain",
        component: () => import(
          /* webpackChunkName: "HomeMain" */ "../views/HomeMain"
        ),
      },
    ]
  },
  {
    path: "/:bid/index.html", // /from?/:from?
    name: "Home",
    props: true,
    component: Home,
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

// function timeout(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }
// async function sleep(runAfter) {
//   await timeout(3000);
//   runAfter();
// }
// router.beforeEach((to, from, next) => {
//   sleep(() => next())
// });

router.beforeResolve(async (to, from, next) => {
  // console.log('name',to.name);
  // console.log('params',to.params);
  // check user
  await store.dispatch('fetchUser') // let user = 
  if(store.state.fp_user.id){ //store.state.user.id
    //console.log('loadUserData',Object.keys(store.state.user).length)
    await store.dispatch('fetchUserData')
  }

  // start here to get business
  let res;
  if(to.params.bid){
    store.dispatch('loadingMain', true);
    if(!store.state.business.id){ // fetch business if not found
      res = await store.dispatch('fetchBusiness', to.params.bid);
      if(res.error){
        return next({ name: '404', replace: true });
      }
    }
    store.dispatch('loadingMain', false);
    next();
    return;
  }
  
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      store.state.loading = true;
  } else {
    // go to 404 not found - FeePlus
    console.log('from',from)
    return next({ name: '404', replace: true });
  }
  next()
})

router.afterEach((/*to, from*/) => {
  // Complete the animation of the route progress bar.
  store.state.loading = false;
})

// router.beforeRouteLeave((to, from, next) => { 
//   console.log('to',to);
//   console.log('from',from);
//   next(false);
// })

// router.beforeRouteEnter((to, from, next) => {
//   console.log(to.params);
// })

export default router;
