<template>
  <div>
    <Navigation /> <!-- :showBack="showBack" -->
    <v-main>
      <v-container>
        <keep-alive>
          <router-view :key="$route.path" /> <!-- :key="$route.path" -->
        </keep-alive>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";

export default {
  name: "Home",
  // data() {
  //   return {
  //     showBack: false
  //   }
  // },
  props: {
    from: {
      default: ''
    }
  },
  created(){
    let loader = document.getElementById("app-loading");
    if(loader) loader.parentNode.removeChild(loader);
  },
  // 20210713 - created for debugging purposes - its iOS safari indexedDB problem
  // created(){
  //   alert('Home created: fullPath "'+this.$router.currentRoute.fullPath+'"')
  //   alert('Home created: path "'+this.$router.currentRoute.path+'"')
  //   alert('Home created: name "'+this.$router.currentRoute.name+'"')
  //   let ps = this.$router.currentRoute.params,
  //     keys = Object.keys(ps);
  //   keys.forEach(k => alert('param: '+k+' "'+ps[k]+'"'));
  // },
  // created(){
  //   if(this.from == 'main'){
  //     this.showBack = true
  //   }
  // },
  components: {
    Navigation,
  },
};
</script>
