import axios from "axios";
import idb from "./indexedDBService.js";

let fpApiGet = function({commit, state}, url){
  commit("SET_LOADING", true);
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_API_URL + url, {
        headers: {
          'uid': state.fp_user.uid,
          'uak': state.fp_user.uak
        },
        withCredential: true,
      })
      .then((res) => {
        console.log("res.data", res.data);
        commit("SET_LOADING", false);
        resolve(res.data);
      })
      .catch((err) => {
        commit("SET_LOADING", false);
        console.log("fpApiGet error", err);
        reject(err);

      });
    });
};

let fpPost = function({commit, state}, url, data){
  commit("SET_LOADING", true);
  return new Promise((resolve) => {
    axios
      .post(url, data, {
        headers: {
          'uid': state.fp_user.uid,
          'uak': state.fp_user.uak
        },
        onUploadProgress: progressEvent => { // TODO 20210630
          // console.log(progressEvent.loaded)
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          state.upload_progress = percentCompleted; // 20210906
          console.log('onUploadProgress', percentCompleted);
        },
        withCredentials: true,
        transformRequest: [
          function(data) {
            const serializedData = []
            for (const k in data) {
              if (typeof data[k] !== 'undefined') { // 20220130 - to prevent zero being flagged out
                if(typeof data[k] == 'object'){
                  if(data[k] instanceof Array) {
                    let i = 0
                    data[k].forEach(o => {
                      Object.entries(o).map(item => {
                        serializedData.push(`${k}[${i}][${item[0]}]=${encodeURIComponent(item[1])}`)
                      })
                      i++
                    })
                  } else { // object
                    Object.entries(data[k]).map(item => {
                      serializedData.push(`${k}[${item[0]}]=${encodeURIComponent(item[1])}`)
                    })
                  }
                } else {
                  serializedData.push(`${k}=${encodeURIComponent(data[k])}`)
                }
              }
            }
            return serializedData.join('&')
          }
        ],
      })
      .then((res) => {
        console.log("fpPost-success", res.data);
        commit("SET_LOADING", false);
        resolve(res.data);
      })
      .catch((err) => {
        commit("SET_LOADING", false);
        console.log("fpPost error", err);
      });
    });
}

let fpApiPost = function({commit, state}, url, data){
  return new Promise((resolve, reject) => {
    fpPost({commit, state}, process.env.VUE_APP_API_URL + url, data).then(res => resolve(res)).catch(err => reject(err));
  })
  // commit("SET_LOADING", true);
  // return new Promise((resolve) => {
  //   axios
  //     .post(process.env.VUE_APP_API_URL + url, data, {
  //       headers: {
  //         'uid': state.fp_user.uid,
  //         'uak': state.fp_user.uak
  //       },
  //       transformRequest: [
  //         function(data) {
  //           const serializedData = []
  
  //           for (const k in data) {
  //             if (data[k]) {
  //               serializedData.push(`${k}=${encodeURIComponent(data[k])}`)
  //             }
  //           }
  
  //           return serializedData.join('&')
  //         }
  //       ],
  //     })
  //     .then((res) => {
  //       console.log("fpApiPost-success", res.data);
  //       commit("SET_LOADING", false);
  //       resolve(res.data);
  //     })
  //     .catch((err) => {
  //       commit("SET_LOADING", false);
  //       console.log(err);
  //     });
  //   });
}

let fpApiPut = function({commit, state}, url, data){
  commit("SET_LOADING", true);
  return new Promise((resolve) => {
    axios
      .put(process.env.VUE_APP_API_URL + url, data, {
        headers: {
          'uid': state.fp_user.uid,
          'uak': state.fp_user.uak
        },
        withCredentials: true,
        transformRequest: [
          function(data) {
            const serializedData = []
  
            for (const k in data) {
              if (data[k]) {
                serializedData.push(`${k}=${encodeURIComponent(data[k])}`)
              }
            }
  
            return serializedData.join('&')
          }
        ],
      })
      .then((res) => {
        console.log("fpPut-success", res.data);
        commit("SET_LOADING", false);
        resolve(res.data);
      })
      .catch((err) => {
        commit("SET_LOADING", false);
        console.log("fpApiDelete error", err);
      });
    });
}

let fpApiDelete = function({commit, state}, url){
  commit("SET_LOADING", true);
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.VUE_APP_API_URL + url, {
        headers: {
          'uid': state.fp_user.uid,
          'uak': state.fp_user.uak
        },
        withCredential: true,
      })
      .then((res) => {
        console.log("res.data", res.data);
        commit("SET_LOADING", false);
        resolve(res.data);
      })
      .catch((err) => {
        commit("SET_LOADING", false);
        console.log("fpApiDelete error", err);
        reject(err);

      });
    });
};

let fpCheckData = function(data){
  if('data' in data){
    let rV = [], // return value
      meta = 'meta' in data ? 'meta' : 'metadata' in data ? 'metadata' : '';
    data.data.forEach(d => {
      let o = {};
      data[meta].forEach((item, index) => {
        o[item] = d[index];
      });
      rV.push(o);
    });
    return rV;
  } else {
    return data;
  }
}

// TODO: what if offline - fetch data from indexeddb and mentioned data might be outdated.

let actions = {
  loading({ commit }, value) {
    commit("SET_LOADING", value);
  },
  loadingMain({ commit }, value) {
    commit("SET_LOADING_MAIN", value);
  },
  // USER
  async login({ commit , state }, payload){
    let data = {}, type = '', user = {}
    switch(payload.provider){
      case 'google':
        type = "/google"
        //data.id_token = payload.id_token
        data.code = payload.code
        break
      case 'facebook':
        type = "/facebook"
        data.accessToken = payload.accessToken
        break
      default:
        data.email = payload.email
        data.password = payload.password
    }
    let res = await fpApiPost({ commit, state }, "/login"+type, data)
    if(res.error == false){
      user = {
        id: "1",
        uid: res.id,
        uak: res.apiKey
      }
      idb.saveToStorage('user', user)
      commit('SET_USER', user)
      // set cookies
      var exdate=new Date();
      exdate.setDate(exdate.getDate() + 100);
      document.cookie = `FPID=${res.id}; expires=${exdate.toUTCString()}; path=/;`;
      document.cookie = `FPEMID=${res.email}; expires=${exdate.toUTCString()}; path=/;`;
      document.cookie = `FPAKID=${res.apiKey}; expires=${exdate.toUTCString()}; path=/;`;
      document.cookie = `FPUAT=1; expires=${exdate.toUTCString()}; path=/;`;
      return res
    } else {
      return res
    }
  },
  async userSignOut() {
    await idb.deleteStorage('user', "1")
    await idb.deleteStorage('user', "2")
    // remove cookies
    document.cookie = "FPID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "FPEMID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "FPAKID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "FPUAT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return true
  },
  async userSignUp({ commit, state }, payload) {
    let data = {
      firstname: payload.firstname,
      lastname: payload.lastname,
      email: payload.email,
      email2: payload.email,
      password: payload.password,
      birthdate: payload.birthdate,
      sex: payload.sex
    }
    let res = await fpApiPost({ commit, state }, "/register", data)
    return res
  },
  async userVerifyEmail({ commit , state }, payload) {
    let res = await fpApiPost({ commit , state }, "/verify",{ email: payload.email, code: payload.code });
    return res;
  },
  async fetchUser({ commit }) {
    let user = await idb.checkStorage('user', "1")
    if(user) await commit('SET_USER', user);
    else { 
      idb.clearCache() // chear cache
      idb.deleteStorage('user',"2") // delete user info
    }
    return user
  },
  async fetchUserData({ commit, state }) {
    let user = await idb.checkStorage('user', "2")
    if(user) commit("FETCH_USER_DATA", user)
    else {
      try {
        let data = await fpApiGet({ commit, state }, "/user/data")
        console.log('data',data)
        if(data.error == false) { 
          commit("FETCH_USER_DATA", data.user)
          data.user.id = "2"; idb.saveToStorage('user',data.user);
          // document.cookie = `FPID=${state.user.uid}`
          // document.cookie = `FPEMID=${state.fp_user.email}`
          // document.cookie = `FPAKID=${state.user.uak}`
        }
        return data
      } catch (error) {
        return false
      }
    }
    return user
  },
  async fetchUserProfile({ commit, state }) {
    try {
      let data = await fpApiGet({ commit, state }, "/user/profile")
      if(data.error == false) { 
        commit("SET_USER_PROFILE", data)
      }
      return data
    } catch (error) {
      return false
    }
  },
  async saveProfile({ commit, state }, payload) {
    try {
      let res = await fpApiPut({ commit, state }, "/user/profile", payload.profile)
      if(res.error == false) { 
        commit("SET_USER_PROFILE", payload.profile)
      }
      return res
    } catch (error) {
      return false
    }
  },
  async setPicture({ commit, state }, payload) {
    let data = {
      imageData: payload.imageData,
      //fileType: payload.fileType // no use
    }
    try {
      let res = await fpApiPut({ commit, state }, "/user/profile/picture", data)
      if(res.error == false) { 
        commit("SET_USER_PROFILE_PIC", res.profile_pic_link)
        let user = state.user
        user.id = "2";
        idb.saveToStorage('user',user);
        return data
      } else {
        return false
      }
    } catch (error) {
      return false 
    }
  },


  // BUSINESS - this one must call first!
  async fetchBusiness({ commit, state }, bid) {
    let cache = await idb.checkCache(`b${bid}`)
    if(Object.keys(cache).length) commit("FETCH_BUSINESS", cache)
    let data = await fpApiGet({ commit, state }, "/businesses/" + bid + "/profile");
    commit("FETCH_BUSINESS", data);
    idb.saveCache(`b${bid}`, data)
    return data;
  },

  // CUSTOMER
  async fetchCustomer({ commit, state }) {
    let cache = await idb.checkCache(`b${state.business.id}c`)
    if(Object.keys(cache).length) {
      commit("FETCH_CUSTOMER", cache)
    }
    try {
      let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + "/app/v2");
      if(data.error == false) {
        commit("FETCH_CUSTOMER", data.customer);
        idb.saveCache(`b${state.business.id}c`, data.customer)
      }
      return data;
    } catch (error) {
      commit("FETCH_CUSTOMER", { active: 0 });
    }
    
  },
  /**
   * @param {Object} payload imageData
   * @returns {String|Boolean} picture link or false if failed
   */
  async setCustomerPicture({ commit, state }, payload) {
    let data = {
      imageData: payload.imageData,
    }
    try {
      let res = await fpApiPut({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? "/dependants/" + payload.dcid : '') + "/app/v2/picture", data)
      if(res.error == false) {
        commit("SET_CUSTOMER_PROFILE_PIC", {
          dcid: payload.dcid,
          picture_link: res.picture_link
        })
        idb.saveCache(`b${state.business.id}e`, state.customer);
        return res.picture_link
      } else {
        return false
      }
    } catch (error) {
      return false 
    }
  },
  async deleteCustomerPicture({ commit, state }, payload) {
    try {
      let res = await fpApiDelete({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? "/dependants/" + payload.dcid : '') + "/app/v2/picture")
      if(res.error == false) {
        commit("SET_CUSTOMER_PROFILE_PIC", {
          dcid: payload.dcid,
          picture_link: null
        })
        idb.saveCache(`b${state.business.id}e`, state.customer);
        return res.picture_link
      } else {
        return false
      }
    } catch (error) {
      return false 
    }
  },
  async fetchCustomerServices({ commit, state }) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + "/app/v2/services/active");
    commit("FETCH_CUSTOMER_SERVICES", data.services);
    return data;
  },
  async fetchServiceCustomerRecords({ commit, state }, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? "/dependants/" + payload.dcid : '') + "/app/services/customers/" + payload.scid + "/records");
    commit("FETCH_SERVICE_CUSTOMER_RECORDS", {
      scid: payload.scid,
      service_records: data.service_records
    });
    return data;
  },
  async fetchServiceRecordAttendances({ commit, state }, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + "/app/services/records/" + payload.srid + "/participations");
    commit("FETCH_SERVICE_RECORD_ATTENDANCES", {
      srid: payload.srid,
      attendances: data.customer_participations
    });
    return data;
  },

  // INVOICES
  async fetchInvoiceForPayment({commit, state}, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.direct_access ? `/customers/direct/${payload.direct_access.email}/${payload.direct_access.access_token}` : '') + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/app/pay/invoices/${payload.iid}`);
    return data;
  },
  async fetchDataForPayment({commit, state}, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.direct_access ? `/customers/direct/${payload.direct_access.email}/${payload.direct_access.access_token}` : '') + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/app/pay`);
    return data;
  },
  async createPaymentForInvoice({commit, state}, payload) {
    let data = await fpApiPost({ commit, state }, "/businesses/" + state.business.id + (payload.direct_access ? `/customers/direct/${payload.direct_access.email}/${payload.direct_access.access_token}` : '') + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/app/pay/invoices/${payload.iid}`, payload.payment);
    return data;
  },
  async createPayment({commit, state}, payload) {
    let data = await fpApiPost({ commit, state }, "/businesses/" + state.business.id + (payload.direct_access ? `/customers/direct/${payload.direct_access.email}/${payload.direct_access.access_token}` : '') + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/app/pay`, payload.payment);
    return data;
  },
  async fetchInvoicePdf({ commit, state}, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/app/invoices/${payload.iid}/pdf`);
    return data;
  },
  // PAYMENTS - 20210626
  async uploadDocumentForInvoice({commit, state}, payload) {
    let data = await fpApiPost({ commit, state }, "/businesses/" + state.business.id + (payload.direct_access ? `/customers/direct/${payload.direct_access.email}/${payload.direct_access.access_token}` : '') + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/app/pay/documents/invoices/${payload.iid}`, payload.payment);
    return data;
  },
  async uploadDocument({commit, state}, payload) {
    let data = await fpApiPost({ commit, state }, "/businesses/" + state.business.id + (payload.direct_access ? `/customers/direct/${payload.direct_access.email}/${payload.direct_access.access_token}` : '') + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/app/pay/documents`, payload.payment);
    return data;
  },

  // NOTES
  async fetchNotes({ commit, state }, payload) { // 20210706 - no direct access
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + "/app/notes");
    commit("FETCH_NOTES", {
      cid: payload.cid,
      notes: fpCheckData(data.notes)
    });
    return data;
  },
  async fetchNote({ commit, state }, payload) { // 20210706 - no direct access
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + "/app/notes/" + payload.cnid);
    commit("FETCH_NOTE", {
      cnid: payload.cnid,
      cid: payload.cid,
      note: data.note
    });
    return data;
  },

  async fetchEvents({ commit, state }) {
    let cache = await idb.checkCache(`b${state.business.id}e`)
    if(Object.keys(cache).length) commit("FETCH_EVENTS", cache)
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + "/app/events");
    commit("FETCH_EVENTS", data.events);
    if(data.error == false) idb.saveCache(`b${state.business.id}e`, data.events)
    return data;
  },
  // EVENTS
  async fetchEventsJoinList({ commit, state }, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/events/join/list?startdate=${payload.startdate}&enddate=${payload.enddate}`);
    commit("FETCH_EVENTS_JOIN_LIST", {
      cid: payload.cid,
      events: data.events
    });
    return data;
  },
  async fetchUserPasses({ commit, state }, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/events/join/passes`);
    return data;
  },
  async createEventJoin({ commit, state }, payload) {
    let res = await fpApiPost({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/events/join`, payload.data);
    return res;
  },
  // APPOINTMENT
  async fetchAppointmentsList({ commit, state}, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + '/appointments/book/appointments');
    commit("FETCH_APPOINTMENTS_LIST", {
      cid: payload.cid,
      appointments: data.appointments
    });
    return data;
  },
  async fetchAppointmentPasses({ commit, state }, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + '/appointments/book/passes');
    return data;
  },
  async fetchAppointmentStakeholders({ commit, state }, payload) {
    try {
      let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/appointments/book/appointments/${payload.appid}/stakeholders`);
      return data;
    } catch (err) {
      throw new Error(err)
    }
  },
  async fetchAppointmentStakeholderSchedules({ commit, state }, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/appointments/book/stakeholders/${payload.shid}/schedules?date=${payload.date}`);
    return data;
  },
  async fetchAppointmentLocations({ commit, state }, payload) {
    let data = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/appointments/book/appointments/${payload.appid}/locations?date=${payload.date}&timefrom=${payload.timefrom}&timeto=${payload.timeto}`);
    return data;
  },
  async createAppointment({ commit, state }, payload) {
    let res = await fpApiPost({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/appointments/book`, payload.data);
    return res;
  },
  async eventTakeLeave({ commit, state }, payload) {
    let data = {
      remark: payload.remark
    }
    let res = await fpApiPost({ commit, state }, "/businesses/" + state.business.id + (payload.dcid ? `/dependants/${payload.dcid}` : '') + `/app/v2/events/${payload.event_id}/${payload.event_date}/leave`, data);
    if(res.error == false){
      commit("SET_EVENTS_TAKE_LEAVE", payload)
    }
    return res;
  },
  // NOTICES
  async fetchNotices({ commit, state }) {
    let res = await fpApiGet({ commit, state }, "/businesses/" + state.business.id + "/app/notice");
    if(res.error == false) {
      commit("SET_NOTICES", res)
      return res
    } else {
      return false
    }
  },
  // REGISTRATION FORM
  async fetchRegistrationForm({ commit, state }, payload) {
    let res = await fpApiGet({ commit, state }, `/businesses/${state.business.id}/register/v2`+(payload.preview_id ? '/preview/'+payload.preview_id : ''));
    if(res.error == false){
      // let obj = JSON.parse(res.form_json)
      commit("FETCH_REGISTRATION_FORM", res)
      return res
    } else {
      return false
    }
  },
  async createRegistrationForm({commit, state}, payload) {
    let data = await fpApiPost({ commit, state }, `/businesses/${state.business.id}/register/v2`, payload.data);
    return data;
  },
  async createRegistrationFormPDF({commit, state}, payload) {
    let data = await fpApiPost({ commit, state }, `/businesses/${state.business.id}/register/v2/preview/${payload.preview_id}/pdf`, payload.data);
    return data;
  },

  async deleteState({commit}, payload){
    commit("DELETE_STATE", payload);
  }

  
};

export default actions;
