const helpers = {
  toCurrency(str) {
    let num = parseFloat(str);
    return (isNaN(num) ? 0.0 : num).toFixed(2);
  },
  /**
   * sends a request to the specified url from a form. this will change the window location.
   * @param {string} path the path to send the post request to
   * @param {object} params the paramiters to add to the url
   * @param {string} [method=post] the method to use on the form
   */
  postForeign(path, params, method) {
    method = method || "post"; // Set method to post by default if not specified.

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for (var key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) { // params.hasOwnProperty(key) // https://eslint.org/docs/rules/no-prototype-builtins
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  },
  /**
   * 20210628 https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
   * @param {*} bytes 
   * @returns 
   */
  bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }
};

export default {
  install(Vue) {
    // install (Vue, options)
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};
