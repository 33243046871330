/**
 * Vue mixin for dialog components to enable dialog closing on browser's back button clicked
 *
 * @link https://github.com/barryong/vue-dialog-mixin
 * @author Barry Ong
 */

import Vue from "vue";
import store from "@/store";

// dialogStore module
const dialogStore = {
  namespaced: true,
  state: () => ({ dialogs: {} }),
  mutations: {
    SHOW_DIALOG(state, dialog) {
      Vue.set(state.dialogs, dialog, true);
    },
    HIDE_DIALOG(state, dialog) {
      if (state.dialogs[dialog]) Vue.set(state.dialogs, dialog, false);
    },
    DELETE_DIALOG(state, dialog) {
      Vue.delete(state.dialogs, dialog);
    },
  },
};
if (!store.hasModule("dialog")) store.registerModule("dialog", dialogStore);

// window.onpopstate
const onpopstate = function(e) {
  if (e.state && e.state.hasDialog) {
    store.commit("dialog/HIDE_DIALOG", e.state.dialog);
  }
};
if (window.onpopstate !== onpopstate) window.onpopstate = onpopstate;

export default {
  data() {
    return {
      dialogName:
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9),
      dialog: false,
      closeCallback: ()=>null
    };
  },
  methods: {
    showDialog() {
      this.$store.commit("dialog/SHOW_DIALOG", this.dialogName);
    },
    closeDialog(closeCallback) {
      if(typeof closeCallback === 'function') this.closeCallback = closeCallback
      this.$store.commit("dialog/HIDE_DIALOG", this.dialogName);
    },
  },
  computed: {
    dialogState() {
      return this.$store.state.dialog.dialogs[this.dialogName];
    },
  },
  watch: {
    dialogState(newValue) {
      this.dialog = newValue ? true : false;
    },
    dialog(newValue) {
      if (newValue === true) {
        let hs = window.history.state ? window.history.state : {};
        hs.hasDialog = true;
        hs.dialog = this.dialogName;
        window.history.replaceState(hs, "");
        window.history.pushState(null, "");
        this.showDialog();
      } else if (newValue == false) {
        this.$store.commit("dialog/DELETE_DIALOG", this.dialogName);
        let hs = window.history.state ? window.history.state : {};
        if (!("hasDialog" in hs && hs.dialog === this.dialogName))
          window.history.go(-1);
        hs.hasDialog = false;
        hs.dialog = "";
        window.history.replaceState(hs, "");
        setTimeout(() => {
          this.$emit("close");
          this.closeCallback();
        }, 300); // 20200814 - fix no animation when closing modal
      }
    },
  },
};
