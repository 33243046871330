<template>
  <div>
    <v-card>
      <v-app-bar app fixed>
        <v-slide-x-transition>
        <v-btn
          v-if="showBack"
          @click="goBack"
          icon
          class="ml-auto mr-2"
          ><v-icon large>mdi-chevron-left</v-icon></v-btn
        >
        </v-slide-x-transition>
        <!-- style="margin-right:-3px" -->
        <v-avatar v-if="userType == 'customer' || showBack" tile color="indigo" size="45">
          <!-- v-else-if="userType == 'customer'" -->
          <v-btn icon @click="showBusinessContacts = true">
            <!-- :ripple="false"  -->
            <img
              v-if="business.logo_pic_link"
              :src="business.logo_pic_link"
              :alt="business.name"
              style="width:45px;height:45px;"
            />
            <v-icon v-else class="white--text headline">mdi-domain</v-icon>
          </v-btn>
        </v-avatar>
        <v-toolbar-title
          v-if="userType == 'customer' || showBack"
          class="d-none d-sm-flex ml-2"
          @click="showBusinessContacts = true"
          style="cursor:pointer"
          >{{ business.name }}</v-toolbar-title
        >
        <business-contacts-dialog
          v-if="showBusinessContacts"
          @close="showBusinessContacts = false"
        ></business-contacts-dialog>
        <!-- <v-btn text depressed x-large :ripple="false" @click="showBusinessContacts = true"> -->
        <a v-if="userType !== 'customer' && !showBack" :href="fpUrl()">
          <v-img
            src="@/assets/images/feeplus-logo-full.png"
            max-width="200"
          />
          <!-- class="d-none d-sm-block" -->
          <!-- <v-img 
            src="@/assets/images/feeplus_logo_sm.png"
            max-width="46"
            class="d-sm-none"
            /> -->
        </a>
        <!-- </v-btn> -->
        
        <v-progress-linear
          :active="loading"
          :indeterminate="uploadProgress == 0 || uploadProgress == 100"
          :value="uploadProgress"
          absolute
          bottom
        ></v-progress-linear>

        <v-spacer></v-spacer>

        <!--
      <v-btn
        text
        :to="{
          name: 'HomeMain'
        }"
      >
        <v-icon>mdi-home</v-icon>
        <span class="d-none d-sm-flex">Home</span>
      </v-btn>

      <v-btn text>
        <v-icon>mdi-calendar</v-icon>
        <span class="d-none d-sm-flex">Schedules</span>
      </v-btn>

      <v-btn text>
        <v-icon>mdi-store</v-icon>
        <span class="d-none d-sm-flex">Store</span>
      </v-btn>

      <v-btn text>
        <v-icon>mdi-forum-outline</v-icon>
        <span class="d-none d-sm-flex">Messages</span>
      </v-btn>
      -->
        <v-btn
          icon
          :to="{
            name: 'HomeMain',
          }"
          exact
          class="mr-2"
        >
          <v-icon>mdi-home</v-icon>
        </v-btn>

        <!-- TODO <v-btn
          icon
          :to="{
            name: 'HomeSchedules',
          }"
        >
          <v-icon>mdi-calendar</v-icon>
        </v-btn> -->

        <!-- TODO <v-btn icon>
          <v-icon>mdi-store</v-icon>
        </v-btn> -->

        <!-- TODO <v-btn icon>
          <v-icon>mdi-forum-outline</v-icon>
        </v-btn> -->
        
        <v-avatar color="indigo" size="45">
          <v-btn icon dark @click="showMyAccount = true">
            <!-- :ripple="false"  -->
            <img
              v-if="user.profile_pic_link"
              :src="user.profile_pic_link"
              alt="Open FeePlus menu"
              style="width:45px;height:45px;border-radius:50%;"
            />
            <span v-else-if="user.initial" class="white--text headline">{{
              user.initial
            }}</span>
            <v-icon v-else class="white--text">mdi-account-circle</v-icon>
          </v-btn>
        </v-avatar>
        <my-account
          v-if="showMyAccount"
          @close="showMyAccount = false"
        ></my-account>
      </v-app-bar>
    </v-card>
  </div>
</template>
<script>
//import BusinessContactsDialog from "@/components/BusinessContactsDialog"
import MyAccount from "@/components/MyAccount"

export default {
  beforeRouteEnter(){
    console.log('beforeRouteEnter')
  },
  beforeRouteUpdate(){
    // console.log('to', to)
    // console.log('from', from)
    console.log('beforeRouteUpdate')
    // next()
  },
  beforeRouteLeave(){
    console.log('beforeRouteLeave')
  },
  data() {
    return {
      showMyAccount: false,
      showBusinessContacts: false,
      showBack: false,
      from: null,
      firstPage: '',
    };
  },
  // props: {
  //   showBack: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  mounted() {
    this.showBack = this.$route.name !== "HomeMain"
    this.firstPage = this.$router.currentRoute.name
    console.log('firstPage', this.firstPage)
  },
  methods: {
    goBack() {
      // console.log('back', this.$router.currentRoute)
      if(this.$router.currentRoute.name === this.firstPage) return this.$router.replace({ name: "HomeMain" })
      else return this.$router.go(-1)
      // if(this.from) return this.$router.go(-1)
      // else return this.$router.replace({ name: "HomeMain" })
      // 20220125 - remove - not sure why need this
      // return this.$router.go(-1)
    },
    fpUrl() {
      return process.env.VUE_APP_FEEPLUS_URL;
    }
  },
  computed: {
    business() {
      return this.$store.state.business;
    },
    fpUser() {
      return this.$store.state.fp_user;
    },
    user() {
      return this.$store.state.user;
    },
    loading() {
      return this.$store.state.loading;
    },
    uploadProgress() {
      return this.$store.state.upload_progress;
    },
    userType() {
      return this.$store.getters.userType();
    }
  },
  watch: {
    $route (to, from){ //to, from){ // to, from
      // console.log('watch-to',to)
      console.log('watch-from',from) // this $route will only change in the second page, so if not called, back button should go back to HomeMain
      // this.from = from;
      // console.log('compare route', this.routes, to === this.routes[0])
      // this.currentPage = to.name
      this.showBack = to.name !== "HomeMain";
    }
  },
  components: {
    MyAccount,
    BusinessContactsDialog: () => import(/* webpackChunkName: "businessContactsDialog" */ "./BusinessContactsDialog")
  },
};
</script>
