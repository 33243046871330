import Vue from "vue";

let mutations = {
  SET_LOADING(state, value) {
    return state.loading = value;
  },
  SET_LOADING_MAIN(state, value) {
    return state.loading_main = value;
  },
  SET_USER(state, value) {
    return state.fp_user = value;
  },
  FETCH_USER_DATA(state, user) {
    return state.user = user;
  },
  SET_USER_PROFILE(state, user_profile) {
    return state.user_profile = user_profile
  },
  SET_USER_PROFILE_PIC(state, profile_pic_link) {
    Vue.set(state.user, 'profile_pic_link', profile_pic_link);
    return state.user;
  },
  SET_CUSTOMER_PROFILE_PIC(state, payload) {
    if(payload.dcid){ // update dependent picture
      let dcIndex = state.customer.dependants.findIndex(d => d.id == payload.dcid)
      Vue.set(state.customer.dependants[dcIndex], 'picture_link', payload.picture_link)
    } else { // update own picture
      Vue.set(state.customer, 'picture_link', payload.picture_link)
    }
  },
  FETCH_BUSINESS(state, business) {
    return state.business = business;
  },
  // NOTES
  FETCH_NOTES(state, payload) {
    if(!(payload.cid in state.notes)) Vue.set(state.notes,payload.cid,{});
    payload.notes.forEach(n => Vue.set(state.notes[payload.cid], n.id, n))
    // console.log('noteess',state.notes);
    // Vue.set(state.notes, payload.cid, payload.notes);
  },
  FETCH_NOTE(state, payload) {
    // state.notes[payload.cid].each(n => )
    Vue.set(state.notes[payload.cid], payload.cnid, payload.note);
  },
  // EVENTS
  FETCH_EVENTS(state, events) {
    return state.events = events;
  },
  FETCH_CUSTOMER(state, customer) {
    return state.customer = customer;
  },
  FETCH_CUSTOMER_SERVICES(state, services) {
    return state.customer_services = services;
  },
  FETCH_SERVICE_CUSTOMER_RECORDS(state, payload) {
    Vue.set(state.service_customer_records, payload.scid, payload.service_records)

    // const item = state.service_customer_records[payload.scid];
    // if(item) Object.assign(item, payload.service_records);
    // else Vue.set(state.service_customer_records, payload.scid, payload.service_records);

    //return state.service_customer_records[payload.scid] = payload.service_records;
    //return state.businesses.push(business) //'bid'+business.id.toString()
  },
  FETCH_SERVICE_RECORD_ATTENDANCES(state, payload) {
    Vue.set(state.service_record_attendances, payload.srid, payload.attendances);
  },
  FETCH_EVENTS_JOIN_LIST(state, payload) {
    Vue.set(state.events_join_list, payload.cid, payload.events);
  },
  FETCH_APPOINTMENTS_LIST(state, payload) {
    Vue.set(state.appointments_list, payload.cid, payload.appointments);
  },
  SET_EVENTS_TAKE_LEAVE(state, payload) {
    state.events.map(e => {
      if(e.event_id == payload.event_id && e.event_date == payload.event_date){
        if(payload.dcid){
          e.dependants.map(ed => {
            if(ed.id === payload.dcid) ed.participation_status = -1
          })
        } else {
          e.participation_status = -1
        }
      }
      return e;
    })
  },
  SET_NOTICES(state, notices) {
    return state.notices = notices;
  },
  DELETE_STATE(state, payload){
    Vue.delete(state[payload.key], payload.id);
  },
  FETCH_REGISTRATION_FORM(state, payload) {
    // Vue.set(state.registration_form, payload);
    let form = payload.form_json ? JSON.parse(payload.form_json) : ''
    if(!form) form = { // set default value if it is null
      fields: [],
      services: {
        groups: {}
      }
    }
    return state.registration_form = form
  }
  // SHOW_DIALOG(state, dialog){
  //   Vue.set(state.dialogs, dialog, true)
  // },
  // HIDE_DIALOG(state, dialog){
  //   if(state.dialogs[dialog]) Vue.set(state.dialogs, dialog, false)
  // },
  // DELETE_DIALOG(state, dialog){
  //   Vue.delete(state.dialogs, dialog)
  // }
}
export default mutations