<template>
  <v-app>
    <main-loading v-if="loading">Loading</main-loading>
    <keep-alive>
      <router-view />
       <!-- :key="$route.path" -->
    </keep-alive>
  </v-app>
</template>

<script>
import MainLoading from "@/components/MainLoading";

export default {
  name: "App",

  // created(){
  //   alert('App created: fullPath "'+this.$router.currentRoute.fullPath+'"')
  //   alert('App created: path "'+this.$router.currentRoute.path+'"')
  //   alert('App created: name "'+this.$router.currentRoute.name+'"')
  //   let ps = this.$router.currentRoute.params,
  //     keys = Object.keys(ps);
  //   keys.forEach(k => alert('param: '+k+' "'+ps[k]+'"'));
  //   // alert('created: "'+window.location.href+'"')
  // },
  data: () => ({
    //
  }),
  computed: {
    loading() {
      return this.$store.state.loading_main;
    }
  },
  components: {
    MainLoading
  }
};
</script>
