// if installed from npm use 'openDB'
import { openDB } from "idb"

/**
 * 20210713 Problem with Safari iOS 14
 * https://github.com/jakearchibald/idb-keyval/issues/120
 * https://github.com/jakearchibald/idb/issues/227
 */

const dbPromise = async () => { //_
  if (!('indexedDB' in window)) {
    // alert('This browser doesn\'t support IndexedDB')
    console.log('This browser doesn\'t support IndexedDB'); // from https://developers.google.com/web/ilt/pwa/working-with-indexeddb
    throw new Error('Browser does not support IndexedDB') // from https://medium.com/@mntlmaxi/make-your-vue-app-last-with-indexeddb-66f02708830e
  }
  
  // var dbPromise = idb.open('test-db1', 1); // from google
  let timer = setTimeout(() => location.reload(), 500); // 20210713 - to solve Safari iOS 14
  let db = await openDB("fpDB", 2, { // return openDB("fpDB", 2, {
    upgrade: (db, oldVersion, newVersion) => {
      console.log('upgradeDb', db)
      console.log('oldVersion', oldVersion)
      console.log('newVersion', newVersion)
      switch (oldVersion) {
        case 0:
          //upgradeDb.createObjectStore('store', {keyPath: 'name'}); // example
          db.createObjectStore("user", {keyPath: "id"});
          db.createObjectStore("cache", {keyPath: "id"});
          break;
        case 1:
          db.createObjectStore("cache", {keyPath: "id"});
          // var peopleStore = upgradeDb.transaction.objectStore('store'); // example
          // peopleStore.createIndex('price', 'price');
      }
    },
    blocked: () => alert('idb blocked'),
    blocking: () => alert('idb blocking'),
    terminated: () => alert('idb terminated')
  })
  clearTimeout(timer);
  return db;
}

/**
 * Save to indexeddb
 * @param {string} storeName Database name
 * @param {object} data Data with in-line id
 */
const saveToStorage = async (storeName, data) => {
  try {
    const db = await dbPromise()
    const tx = db.transaction(storeName, 'readwrite')
    const store = tx.objectStore(storeName)
    store.put(data)
    return tx.complete
  } catch (error) {
    return error
  }
}

/**
 * Check and get data from storage
 * @param {string} storeName Database name
 * @param {number} id Id of data
 */
const checkStorage = async (storeName, id) => {
  try {
    const db = await dbPromise()
    const tx = db.transaction(storeName, 'readonly')
    const store = tx.objectStore(storeName)
    return await store.get(id)
  } catch (error) {
    return error
  }
}

const deleteStorage = async (storeName, id) => {
  try {
    const db = await dbPromise()
    const tx = db.transaction(storeName, 'readwrite')
    const store = tx.objectStore(storeName)
    store.delete(id)
    return tx.complete
  } catch (error) {
    return error
  }
}

/**
 * Save to indexeddb - cache database
 * - storage format: {
 *  id,       // id of the record
 *  updated,  // date and time in YYYY-MM-DDTHH:mm:ss.sssZ format (ISO-8601)
 *  data      // data of object / array
 * }
 * @param {number} id Id of the record
 * @param {object} data 
 */
const saveCache = async (id, data) => {
  try {
    const record = {
      id,
      updated: new Date().toISOString(),
      data
    }
    return saveToStorage('cache', record) 
  } catch (error) {
    return error
  }
}

/**
 * Check cache data in storage (indexeddb)
 * @param {number} id Id of data
 */
const checkCache = async (id) => {
  try {
    let record = await checkStorage('cache', id)
    if(record) return record.data
    else return {}
  } catch (error) {
    return error
  }
}

/**
 * Clear all cache if user is invalid
 * Regular Expression:
 * /(^b[0-9]+$|^b[0-9]+e$)/g -to clear b10001 and b 100001e
 */
const clearCache = async () => {
  try {
    const db = await dbPromise()
    // const tx = db.transaction('cache', 'readwrite')
    // const store = tx.objectStore('cache')
    // store.clear() // this is to clear all
    let cursor = await db.transaction('cache', 'readwrite').store.openCursor();
    while (cursor) {
      // console.log(cursor.key, cursor.value);
      if(!cursor.key.match(/(^b[0-9]+$)/g)){
        console.log(`DELETE: ${cursor.key}`, cursor.value);
        cursor.delete()
      }
      cursor = await cursor.continue();
    }
    //return tx.complete
  } catch (error) {
    return error
  }
}

export default {
  checkStorage,
  saveToStorage,
  deleteStorage,
  saveCache,
  checkCache,
  clearCache
}