//import dateTimeMixin from "@/mixins/dateTimeMixin"
import moment from "moment"

let getters = {
  getBusinessProfile: state => (bid) => {
    return state.businesses.find(b => b ? b.id == bid || b.username === bid : false);
    // let retVal = state.businesses[bid];
    // if(retVal) return retVal;
    // retVal = state.businesses.find(b => b.username == bid);
    // if(retVal) return retVal;
    // return null;
    // switch (typeof bid){
    //   case 'number': return state.businesess[bid];
    //   case 'string' && bid != "": return state.businesses.find(b => b.username == bid);
    //   default: return null;
    // }
  },
  getCustomerService: state => (cid, scid) => {
    return typeof state.customer_services[cid] === 'undefined' ? {} : state.customer_services[cid].find(
      (s) => s.service_customer_id == scid
    );
  },
  isDependant: state => (cid) => {
    if(state.customer.dependants) return state.customer.dependants.find(cd => cd.id == cid);
    else return false;
  },
  isCustomer: state => () => {
    if(state.customer.id > 0) return true;
    else return false; 
  },
  userType: state => () => {
    if(state.fp_user.uid && state.fp_user.uak){
      if(state.customer.id > 0) return 'customer'
      else return 'guest'
    } else {
      return 'public'
    }
  },
  expiredSubscriptions: state => (cid) => {
    //return state.customer_services[cid].filter(cs => dateTimeMixin.isExpired(cs.service_dateto)).length
    if(state.customer_services[cid])
      return state.customer_services[cid].filter(cs =>  moment(cs.service_dateto).isBefore(moment().clone().startOf("day"),"d")).length;
  },
  activePasses: state => (cid) => {
    let activePasses = 0
    if(state.customer_services[cid]) {
      state.customer_services[cid].filter(cs => {
        if(!moment(cs.service_dateto).isBefore(moment().clone().startOf("day"),"d")){
          activePasses += cs.passes_counter
        }
      })
    }
    return activePasses
  }
}

export default  getters