let state = {
  loading: false,
  business: {}, // cache id: b${bid}
  customer: {}, // cache id: b${bid}c
  //customers: [],
  customer_services: {}, // cache id: b${bid}cs // a list of services for customer (key: customer_id)
  service_customer_records: {}, // cache id: b${bid}scr // a list of service's service records (key: service_id)
  service_record_attendances: {}, // cache id: b${bid}sra
  notes: {},
  events: [], // cache id: b${bid}e
  events_join_list: {}, // cache id: b${bid}ejl // THINK: need to cache this or not
  appointments_list: {}, // THINK: need to cache this or not
  notices: {}, // 20200917 - store all notice
  //service_customers: {},
  fp_user: { // user id: "1"
    // uid: 1,
    // uak: '8579f74bfcc98d86ccecf75c50f71ad3'
  },
  user: {}, // user id: "2"
  user_profile: {},
  pending_actions: [],
  upload_progress: 0 ,// 20210907
  registration_form: {
    fields: [],
    services: {
      groups: {}
    }
  }
}

export default state;